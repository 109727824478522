@import url(https://fonts.googleapis.com/css?family=Nunito:300,300i,400,400i,600,600i,700,700i&display=swap);
.header{justify-content:space-between;align-items:center}.header .menu{position:absolute;top:27px;left:20px;cursor:pointer}@media (min-width: 1024px){.header .menu{display:none}}.header-nav{flex:1 1}.header-nav h3{font-size:16px;letter-spacing:0.96px;cursor:pointer}.header-nav h3.active,.header-nav h3:hover{color:#c52233}.header-nav .spacer{width:6px;height:22px;border-radius:10px;background-color:#c52233;margin:0 20px}@media (max-width: 1024px){.header-nav{flex-direction:column}}.header-logo{flex:1 1;align-items:center}.header-logo h1{font-family:TheBredan;font-size:54px;letter-spacing:3.24px;color:#001a23;-webkit-animation:animShadow 4s infinite;animation:animShadow 4s infinite;-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out}.header-logo p{text-transform:uppercase;font-weight:lighter;font-size:12px;letter-spacing:0.72px;color:#001a23}@media (max-width: 1024px){.header-logo{margin:24px 0}}.header-links{flex:1 1;justify-content:right;display:flex}.header-links a{opacity:0.6;font-size:16px;letter-spacing:0.96px;color:#001a23;text-decoration:none}.header-links a:not(:last-child){margin-right:20px}.header-links a:hover{opacity:1}@media (max-width: 1024px){.header-links{display:none}}@media (max-width: 1024px){.header.row{flex-direction:column}}@-webkit-keyframes animShadow{0%{text-shadow:none}100%{text-shadow:1px 1px aqua}}@keyframes animShadow{0%{text-shadow:none}100%{text-shadow:1px 1px aqua}}

.links-menu{position:absolute;top:0;left:0;background-color:#001a23;height:100vh;width:100vw;overflow:hidden;z-index:1}.links-menu .close{position:absolute;top:27px;left:20px;cursor:pointer}.links-menu .header-links{display:flex;flex-direction:column;align-items:center;justify-content:center}.links-menu .header-links a{opacity:0.6;font-size:16px;letter-spacing:0.96px;color:#fbfffe;text-decoration:none;-webkit-transition:all 0.3s ease;transition:all 0.3s ease}.links-menu .header-links a:not(:last-child){margin-right:0;margin-bottom:20px}.links-menu .header-links a:hover{opacity:1}

.about.body{border-radius:10px;background-color:#001a23;margin-top:30px}.about.body .main-image{height:auto;width:34%}.about.body .main-image img{border-radius:10px 0px 0px 10px;object-fit:cover;height:100%;width:100%}.about.body .main-text{width:100%;align-items:center;padding:30px;color:#fbfffe}.about.body .main-text span{font-size:14px;font-weight:300;letter-spacing:1.4px;margin-bottom:10px;margin-top:10px}.about.body .main-text span.greet{font-size:18px;letter-spacing:1.08px;margin-bottom:0}.about.body .main-text h1{display:flex;align-items:center;font-size:18px;letter-spacing:1.08px}.about.body .main-text h1 svg{margin-left:10px}.about.body .main-text h1.book{color:#c52233}.about.body .main-text button{font-family:inherit;border-radius:30px;background-color:#c52233;font-size:12px;letter-spacing:0.72px;color:#fbfffe;padding:7px 14px;border:none;cursor:pointer;display:flex;align-items:center}.about.body .main-text button svg{padding-left:2px;-webkit-transition:all 0.3s ease;transition:all 0.3s ease}@media (max-width: 1024px){.about.body .main-text button svg{padding-left:10px}}.about.body .main-text button:hover svg{padding-left:10px}@media (max-width: 1024px){.about.body{margin-top:0}.about.body.row{flex-direction:column;border-radius:0}.about.body .main-image{height:50vh;width:100%}.about.body .main-image img{border-radius:0}.about.body .main-text{padding:0;margin-top:30px;text-align:center}.about.body .main-text span{margin:0 40px}.about.body .main-text span.greet{margin-bottom:10px}.about.body .main-text h1.bittu,.about.body .main-text h1.book{margin-bottom:5px;margin-top:5px}.about.body .main-text h1.bittu ~ span,.about.body .main-text h1.book ~ span{margin-bottom:10px}.about.body button{margin-bottom:40px}}

.books.body .search{margin:30px 0;display:flex;justify-content:center;align-items:center}.books.body .search input{width:230px;height:30px;border-radius:30px;border:solid 1px #c52233;padding-left:14px;font-family:inherit}.books.body .search input::-webkit-input-placeholder{font-family:Nunito;font-size:12px;font-weight:normal;font-style:italic;letter-spacing:0.72px;color:#828282}.books.body .search input:-ms-input-placeholder{font-family:Nunito;font-size:12px;font-weight:normal;font-style:italic;letter-spacing:0.72px;color:#828282}.books.body .search input::-ms-input-placeholder{font-family:Nunito;font-size:12px;font-weight:normal;font-style:italic;letter-spacing:0.72px;color:#828282}.books.body .search input::placeholder{font-family:Nunito;font-size:12px;font-weight:normal;font-style:italic;letter-spacing:0.72px;color:#828282}.books.body .search svg{position:relative;left:-26px}.books.body .books{display:flex;flex-wrap:wrap;justify-content:center}.books.body .books .book{margin:20px;background-color:#001a23;color:#fbfffe;width:290px;height:190px;padding:20px;border-radius:10px;justify-content:space-between;align-items:center}.books.body .books .book .heading{justify-content:space-between;margin-bottom:8px}.books.body .books .book .heading .rating{color:#c52233}.books.body .books .book .review-brief{font-size:12px;letter-spacing:0.72px;color:#fbfffe;padding:0 10px;align-items:center;justify-content:space-between}.books.body .books .book .review-brief button{margin-top:10px}.books.body .books .book button{font-family:inherit;border-radius:30px;background-color:#c52233;font-size:12px;letter-spacing:0.72px;color:#fbfffe;padding:7px 14px;border:none;cursor:pointer;display:flex;align-items:center}.books.body .books .book button svg{padding-left:2px;-webkit-transition:all 0.3s ease;transition:all 0.3s ease}@media (max-width: 1024px){.books.body .books .book button svg{padding-left:10px}}.books.body .books .book button:hover svg{padding-left:10px}

body[data-aos-duration='4000'] [data-aos],[data-aos][data-aos][data-aos-duration='4000']{-webkit-transition-duration:4000ms;transition-duration:4000ms}@font-face{font-family:theBredan;src:url("/static/media/The Bredan Demo.88b67e7a.otf")}.App{font-family:Nunito, Arial, Helvetica, sans-serif;padding:40px 50px;padding-bottom:0;-webkit-transition:all 0.3s ease;transition:all 0.3s ease;-webkit-font-smoothing:antialiased}@media (max-width: 1024px){.App{padding:0;padding-top:30px}}.App .footer{display:flex;justify-content:center;color:#c52233;padding:20px 0;font-size:14px}.row{display:flex;flex-direction:row}.column{display:flex;flex-direction:column}.body{max-width:1140px;margin-left:auto;margin-right:auto}::selection{background:#c52233;color:#fbfffe}a{text-decoration:none}

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}

