@import '../../styles/config.scss';


.links-menu {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $base-black;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: 1;

  .close {
    position: absolute;
    top: 27px;
    left: 20px;
    cursor: pointer;
  }

  .header-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    a {
      opacity: 0.6;
      font-size: 16px;
      letter-spacing: 0.96px;
      color: $cool-white;
      text-decoration: none;
      transition: all 0.3s ease;
      
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
      }
      
      &:hover {
        opacity: 1;
      }
    }
  }
}