$primary-color: #c52233;
$base-black: #001a23;
$cool-white: #fbfffe;


@mixin primary-button {
  button {
    font-family: inherit;
    border-radius: 30px;
    background-color: $primary-color;
    font-size: 12px;
    letter-spacing: 0.72px;  
    color: $cool-white;
    padding: 7px 14px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    
    svg {
      padding-left: 2px;
      transition: all 0.3s ease;

      @media (max-width: $desktop-width) {
        padding-left: 10px;
      }
    }

    &:hover {
      svg {
        padding-left: 10px;
      }
    }
  }
}

$desktop-width: 1024px;

@mixin mobile() {
  @media (max-width: $desktop-width) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: $desktop-width) {
    @content;
  }
}