@import url('https://fonts.googleapis.com/css?family=Nunito:300,300i,400,400i,600,600i,700,700i&display=swap');
@import './config.scss';

body[data-aos-duration='4000'] [data-aos], [data-aos][data-aos][data-aos-duration='4000']{
  transition-duration: 4000ms;
}

@font-face {
  font-family: theBredan;
  src: url('./../assets/fonts/the_bredan/The\ Bredan\ Demo.otf');
}

.App {
  font-family: Nunito, Arial, Helvetica, sans-serif;
  padding: 40px 50px;
  padding-bottom: 0;
  transition: all 0.3s ease;
  -webkit-font-smoothing: antialiased;

  @include mobile() {
    padding: 0;
    padding-top: 30px;
  }

  .footer {
    display: flex;
    justify-content: center;
    color: $primary-color;
    padding: 20px 0;
    font-size: 14px;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.body {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}

::selection {
  background: $primary-color;
  color: $cool-white;
}

a {
  text-decoration:none;
}