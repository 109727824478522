@import "../../styles/config.scss";

.books.body {
  .search {
    margin: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      width: 230px;
      height: 30px;
      border-radius: 30px;
      border: solid 1px #c52233;
      padding-left: 14px;
      font-family: inherit;

      &::placeholder {
        font-family: Nunito;
        font-size: 12px;
        font-weight: normal;
        font-style: italic;
        letter-spacing: 0.72px;
        color: #828282;
      }
    }

    svg {
      position: relative;
      left: -26px;
    }
  }

  .books {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    .book {
      margin: 20px;
      background-color: $base-black;
      color: $cool-white;
      width: 290px;
      height: 190px;
      padding: 20px;
      border-radius: 10px;
      justify-content: space-between;
      align-items: center;

      .heading {
        justify-content: space-between;
        margin-bottom: 8px;

        .rating {
          color: $primary-color;
        }
      }

      .review-brief {
        font-size: 12px;
        letter-spacing: 0.72px;
        color: #fbfffe;
        padding: 0 10px;
        align-items: center;
        justify-content: space-between;

        button {
          margin-top: 10px;
        }
      }

      @include primary-button;
    }
  }
}