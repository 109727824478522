@import "../../styles/config.scss";

.header {
  justify-content: space-between;
  align-items: center;

  .menu {
    position: absolute;
    top: 27px;
    left: 20px;
    cursor: pointer;

    @include desktop {
      display: none;
    }
  }

  &-nav {
    flex: 1;

    h3 {
      font-size: 16px;
      letter-spacing: 0.96px;
      cursor: pointer;

      &.active,
      &:hover {
        color: $primary-color;
      }
    }

    .spacer {
      width: 6px;
      height: 22px;
      border-radius: 10px;
      background-color: $primary-color;
      margin: 0 20px;
    }

    @include mobile() {
      flex-direction: column;
    }
  }

  &-logo {
    flex: 1;
    align-items: center;

    h1 {
      font-family: TheBredan;
      font-size: 54px;
      letter-spacing: 3.24px;
      color: $base-black;
      animation: animShadow 4s infinite;
      animation-timing-function: ease-in-out;
    }

    p {
      text-transform: uppercase;
      font-weight: lighter;
      font-size: 12px;
      letter-spacing: 0.72px;
      color: $base-black;
    }

    @include mobile() {
      margin: 24px 0;
    }
  }

  &-links {
    flex: 1;
    justify-content: right;
    display: flex;

    a {
      opacity: 0.6;
      font-size: 16px;
      letter-spacing: 0.96px;
      color: $base-black;
      text-decoration: none;

      &:not(:last-child) {
        margin-right: 20px;
      }

      &:hover {
        opacity: 1;
      }
    }

    @include mobile{
      display: none;
    }
  }

  &.row {
    @include mobile() {
      flex-direction: column;
    }
  }
}

@keyframes animShadow {
  0% {
    text-shadow:none;
  }
  100% {
    text-shadow: 1px 1px aqua;
  }
}
