@import "../../styles/config.scss";

.about.body {
  border-radius: 10px;
  background-color: $base-black;
  margin-top: 30px;

  .main-image {
    height: auto;
    width: 34%;

    img {
      border-radius: 10px 0px 0px 10px;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .main-text {
    width: 100%;
    align-items: center;
    padding: 30px;
    color: $cool-white;

    span {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 1.4px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    span.greet {
      font-size: 18px;
      letter-spacing: 1.08px;
      margin-bottom: 0;
    }

    h1 {
      display: flex;
      align-items: center;
      font-size: 18px;
      letter-spacing: 1.08px;

      svg {
        margin-left: 10px;
      }

      &.book {
        color: $primary-color;
      }
    }

    @include primary-button;
  }

  @include mobile() {
    margin-top: 0;

    &.row {
      flex-direction: column;
      border-radius: 0;
    }

    .main-image {
      height: 50vh;
      width: 100%;

      img {
        border-radius: 0;
      }
    }

    .main-text {
      padding: 0;
      margin-top: 30px;
      text-align: center;

      span {
        margin: 0 40px;

        &.greet {
          margin-bottom: 10px;
        }
      }

      h1.bittu, h1.book {
        margin-bottom: 5px;
        margin-top: 5px;

        &~span {
          margin-bottom: 10px;
        }
      }
    }

    button {
      margin-bottom: 40px;
    }
  }
}
